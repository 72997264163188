<template>
  <ion-page>

    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <h1>Login</h1>
        <form action="#" @submit.prevent="submit">
          <ion-grid class="login-form">
            <ion-row>
              <ion-col>
                <ion-item lines="none">
                    <ion-label class="standard-label" position="stacked">Email</ion-label>
                    <ion-input class="standard-input" type="email" name="email" v-model="form.email"></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-label class="standard-label" position="stacked">Password</ion-label>
                    <ion-input class="standard-input" type="password" name="password" v-model="form.password"></ion-input>
                </ion-item>
                <template v-if="error_message != ''">
                  <div class="error-message">{{ error_message }}</div>
                </template>
                <ion-button color="secondary" fill="solid" class="login-btn" type="submit">Login</ion-button>
              </ion-col>
            </ion-row>

          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar, 
  IonLabel, IonInput, IonItem, IonButton, IonCol, IonRow, IonGrid, modalController
} from '@ionic/vue';
import { mapActions } from 'vuex'
import LoadingModal from '../modals/Loading.vue';

export default {
  name: 'Login',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonCol, IonRow, IonGrid, 
    IonLabel, IonInput, IonItem
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
      },
      error_message : ''
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      getMe: 'brightchecker/me',
    }),

    async submit () {
      this.loadingTriggered();
      this.signIn(this.form).then((success) => {
        // you access the value from the promise here
        if(success == false){
          // error
          this.closeModal();
          this.error_message = "There was an error logging in with those details."
        } else {

          this.getMe().then(() => {
            this.closeModal();
            this.error_message = "";
            this.$router.push({ path : "/" })
          })

        }
      });
    },
    async loadingTriggered(){
      this.loadingModal = await modalController
      .create({
          component: LoadingModal,
          cssClass: 'loading-modal',
      })
      return this.loadingModal.present();
    },
    async closeModal(){
      this.loadingModal.dismiss();
    }
  }
}
</script>

<style scoped>
  #container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  h1 {
      text-transform: uppercase;
  }

  .login-btn {
    margin-top : 20px;
  }

  .error-message {
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
    color: #b41f1f;
  }
</style>